import {parseSearchParams} from "@utils/urls";
import {safeParse} from "../core";
import {fetchFeatureFlags as fetchFeatureFlagsNoMemo} from "../core";
import memoizee from "memoizee";
import {MsMap} from "@constants/MsMap";
import {FeatureFlagEvalOptions, FeatureFlagMap} from "../types";
import {isBrowser} from "@utils/isBrowser";

type FeatureFlagEvalOptionsClient = FeatureFlagEvalOptions & {
  shouldMemoize?: boolean;
};

const getQueryOnClient = () =>
  isBrowser()
    ? parseSearchParams(new URLSearchParams(window.location.search)).mapValues(safeParse)
    : {};

const clientFetchFeatureFlags = <T extends FeatureFlagMap>(
  flagDefaultPairs: T,
  options: Partial<FeatureFlagEvalOptionsClient> = {},
): Promise<T> =>
  fetchFeatureFlagsNoMemo(flagDefaultPairs, {
    ...options,
    query: options.query ?? getQueryOnClient(),
  });

const memoizedFetchFeatureFlags = memoizee(clientFetchFeatureFlags, {
  maxAge: MsMap.ONE_MINUTE * 5, // memoize for 5 mins
  promise: true, // prevents caching bad response (exceptions)
  normalizer: args => JSON.stringify(args[0]), // use first arg as a cache key. eg: `"{[FeatureFlag.GROWTH_DISCOVERY_LOCATIONS_SECTION_1]: false,}"`
});

/**
 * Fetch feature flags on the client
 */
export const fetchFeatureFlags = <T extends FeatureFlagMap>(
  flagDefaultPairs: T,
  options: FeatureFlagEvalOptionsClient = {},
): Promise<T> =>
  options.shouldMemoize === false
    ? clientFetchFeatureFlags(flagDefaultPairs, options)
    : memoizedFetchFeatureFlags(flagDefaultPairs, options);
