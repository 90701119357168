import "isomorphic-fetch";

import * as types from "./types";
import {PracticeApptReason} from "./types";
import moment from "moment-timezone";
import queryString from "query-string-for-all";
import {GA as GAParams} from "src/utils/googleAnalytics";

import {dev, laTimezone} from "../components/_common/_constants";
import {getApiUrl} from "src/constants/apiUrls";
import {isBrowser} from "src/utils/isBrowser";
import {PracticeId} from "../constants/practiceIds";
import {withGenericErrorNonOks} from "../utils/fetch/responseValidation";
import {ignoreArrays} from "../utils/ignoreArrays";
import {getVisitDataHeaders} from "src/utils/visitTracking";
import {
  AnyNextRequest,
  AnyNextResponse,
  GetServerSidePropsRequest,
  clientIpFromAnyNextRequest,
  requestHeadersAsObject,
} from "src/app/_pages-transition/server";
import {IncomingMessage, ServerResponse} from "http";

export const apiUrl = getApiUrl();

export function guess(): string {
  try {
    return moment.tz.guess();
  } catch (err) {
    return laTimezone; // could also be something like moment.tz.names()[0];
  }
}
export const DEFAULT_TIMEZONE = guess();

const fetchValidated = withGenericErrorNonOks(fetch);

/**
 * @deprecated Use composable fetch tools `/src/utils/fetch/fetches.ts` instead.
 */
export const api = {
  getDefaultHeaders: (req?: AnyNextRequest, res?: AnyNextResponse, sendVisitHeaders = false) => {
    const headers = req ? requestHeadersAsObject(req?.headers) : {};
    const clientIp =
      ignoreArrays(headers["x-real-ip"]) ||
      ignoreArrays(headers["x-forwarded-for"]) ||
      ignoreArrays(headers["X-Forwarded-For"]) ||
      (req && clientIpFromAnyNextRequest(req)) ||
      "";

    const userAgent = ignoreArrays(headers["user-agent"]) || undefined;
    const visitHeaders = sendVisitHeaders ? getVisitDataHeaders(req, res) : {};
    return {
      CarbonAppType: "Web",
      ...(clientIp
        ? {
            "X-Forwarded-For": clientIp.startsWith("::ffff:")
              ? clientIp.replace("::ffff:", "")
              : clientIp,
          }
        : {}),
      ...(userAgent ? {"User-Agent": userAgent} : {}),
      ...visitHeaders,
    };
  },
  get: (
    path?: string,
    params?: Record<string, any>,
    headerParams = {},
    external = false,
    req?: GetServerSidePropsRequest,
    res?: ServerResponse<IncomingMessage>,
    sendVisitHeaders = false,
  ) => {
    const qs = params ? `?${queryString.stringify(params)}` : "";
    // console.log('get', `${apiUrl}${path}${qs}`);
    return fetchValidated(`${external ? "" : apiUrl}${path}${qs}`, {
      headers: {
        ...api.getDefaultHeaders(req, res, sendVisitHeaders),
        ...headerParams,
      },
    })
      .then(response => response.json())
      .catch(error => console.error("Error:", error));
  },

  post: (
    path: string,
    data?: unknown,
    external = false,
    headerParams = {},
    // @ts-expect-error TS7006: Parameter 'credentials' implicitly has an 'any' type.
    credentials?,
    req?: GetServerSidePropsRequest,
    res?: ServerResponse<IncomingMessage>,
    sendVisitHeaders = false,
  ) => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...api.getDefaultHeaders(req, res, sendVisitHeaders),
      ...headerParams,
    };

    const options = {
      method: "POST",
      credentials,
      headers,
      body: JSON.stringify(data || {}),
    };

    const root = external ? path : `${apiUrl}${path}`;

    return fetchValidated(root, options).catch(error => {
      console.error("Error:", error);
      return error;
    });
  },

  getWithTimeout: (
    timeout = 7000,
    path?: string,
    // @ts-expect-error TS7006: Parameter 'data' implicitly has an 'any' type.
    data?,
    external = false,
    headerParams = {},
    req?: GetServerSidePropsRequest,
    res?: ServerResponse<IncomingMessage>,
    sendVisitHeaders = false,
  ) => {
    const headers = {
      ...api.getDefaultHeaders(req, res, sendVisitHeaders),
      ...headerParams,
    };

    const options = {
      headers,
    };

    const root = external ? `${path}` : `${apiUrl}${path}`;

    return Promise.race([
      fetch(root, options),
      new Promise<never>((_, reject) => setTimeout(() => reject(new Error("timeout")), timeout)),
    ]);
  },
};

export const fetchDoctorsByPracticeId = (id: string) => api.get(`/hib/practices/${id}/doctors`);

export const fetchPracticeBySlug = async (slug: string) => {
  const p = await api.get(`/hib/practices/bySlug/${slug}`).catch(() => ({}));
  if (p) {
    p.doctors = await fetchDoctorsByPracticeId(p.id);

    p.insurances = await api.get(`/hib/practices/${p?.id}/visible-insurances`);

    p.virtualOnly =
      p.modalities &&
      // @ts-expect-error TS7006: Parameter 'mod' implicitly has an 'any' type.
      !p.modalities.find(mod => mod.code === "WalkIn" || mod.code === "ClinicVisit");

    if (p.virtualOnly) {
      // adding fake location
      p.locations.push({
        slug: "appointment",
        id: "appointment",
      });
    }
    return p;
  }
  return {};
};

export const fetchPracticeById = (id: string) =>
  api
    .post("/hib/practices/getForCodes", id)
    .then(response => response.json())
    .then(r => r[0]);

export const fetchDoctor = (slug: string): Promise<types.Doctor> =>
  api.get(`/hib/doctors/bySlug/${slug}`);

export const fetchReasonBySlug = (
  slug: string,
  practiceId = PracticeId.CARBON,
): Promise<PracticeApptReason> =>
  api
    .get(`/hib/apptReasons/bySlug/${slug}?practiceIds=${practiceId}`)
    .then(response => (Array.isArray(response) && response[0]) || null);

export const fetchReasonsByPracticeId = (id: string) => api.get(`/hib/practices/${id}/apptReasons`);

export const fetchAllSpecialties = () => api.get("/hib/specialties/all");

export const GA = (data: GAParams) => {
  if (!isBrowser()) return;
  return !dev
    ? window && typeof window === "object" && window.ga
      ? window.ga("send", "event", data.category, data.action, data.label)
      : "no GA implemented"
    : console.log("send", "event", data.category, data.action, data.label);
};

// @ts-expect-error TS7006: Parameter 'data' implicitly has an 'any' type.
export const addToSalesforce = data => {
  return new Promise((res, rej) => {
    api
      .post("/api/salesforce", data, true, {}, "same-origin")
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          return rej(response);
        }
        return res(response);
      });
  });
};

/**
 *
 * @param latitude
 * @param longitude
 * @param distance In degrees; 1 degree approximately corresponds to 111 km or 69 miles
 * Unit of measure depends on SRID set on API level
 * More info: https://www.usna.edu/Users/oceano/pguth/md_help/html/approx_equivalents.htm
 */
export const getNearbyDoctors = (
  latitude: number,
  longitude: number,
  distance: number,
): Promise<types.Doctor[]> => api.get("/hib/doctors/getNearby", {latitude, longitude, distance});
