import {apiUrl} from "@services/api";
import {getPublicEnvVar} from "@utils/getEnvVar";

export enum FeatureFlag {
  GROWTH_WEBSITE_BANNER = "GROWTH_WEBSITE_BANNER",
  WEBSITE_SHOW_BOOK_BTN_ON_NAV = "WEBSITE_SHOW_BOOK_BTN_ON_NAV",
  WEB_SCHEDULING_ABANDONED_FLOW_ENABLED = "WEB_SCHEDULING_ABANDONED_FLOW_ENABLED",
  WEB_SCHEDULING_ALWAYS_SHOW_NEARBY_LOCATIONS_ENABLED = "WEB_SCHEDULING_ALWAYS_SHOW_NEARBY_LOCATIONS_ENABLED",
}

export const FEATURE_FLAG_ENDPOINT = `${
  getPublicEnvVar("FEATURE_FLAG_ENDPOINT") || apiUrl
}/hib/feature-flags`;
