import * as Sentry from "@sentry/nextjs";
import isbot from "isbot";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import {dev} from "src/components/_common/_constants";

import {requestHeadersAsObject} from "src/app/_pages-transition/server";
import {ignoreArrays} from "@utils/ignoreArrays";
import {EvaluationResponse, FeatureFlagEvalOptions, FeatureFlagMap} from "./types";
import {api} from "@services/api";
import {FEATURE_FLAG_ENDPOINT} from "./constants";
import tee from "@utils/promise/tee";

export const safeParse = (val: unknown): unknown => {
  try {
    return JSON.parse(val as string);
  } catch {
    return val;
  }
};

/**
 * Looks through a query object for keys that match a flag in `flagsToCheck`.
 * Tries to parse the value with `JSON.parse`, on fail, return the raw value
 * Returns a map of found keys.
 * @param flagsToCheck Flags to look for in the query object
 * @param query The query object to check against
 */
export const getQueryOverrides = <T = FeatureFlagMap>(
  flagDefaultPairs: T,
  query = {} as Record<string, unknown>,
): Partial<T> => {
  const flagsToCheck =
    typeof flagDefaultPairs === "object" && flagDefaultPairs
      ? (Object.keys(flagDefaultPairs) as (keyof T)[])
      : [];
  return flagsToCheck.reduce<Partial<T>>((acc, next) => {
    const keyIsInQuery = next in query;
    const parsedValue = keyIsInQuery && safeParse(query[next as string]);
    const shouldAddOverride = keyIsInQuery && typeof parsedValue === typeof flagDefaultPairs[next];
    return shouldAddOverride ? {...acc, [next]: parsedValue} : acc;
  }, {} as Partial<T>);
};

export const getSuccessfulEvaluations = <T = FeatureFlagMap>(
  response: EvaluationResponse<T>,
): Partial<T> =>
  response
    .filter(evaluation => evaluation.isSuccessful)
    .reduce((acc, next) => ({...acc, [next.featureFlag]: next.featureFlagValue}), {} as T);

export const fetchFeatureFlags = <T extends FeatureFlagMap>(
  flagDefaultPairs: T,
  options: Partial<FeatureFlagEvalOptions> = {},
): Promise<T> => {
  const {
    overrideAttribute = {},
    anonymous = false,
    localFetch = fetch,
    featureFlagsFromRedux = {},
    req,
    res,
    query = {},
  } = options;
  const headers = req ? requestHeadersAsObject(req?.headers) : {};
  const userAgent = ignoreArrays(headers["user-agent"]);
  const isBot = userAgent ? isbot(userAgent) : false;
  // Remove flag from list if we have value in store already
  const unevaluatedDefaultFlags = omit(flagDefaultPairs, Object.keys(featureFlagsFromRedux)) as T;

  if (isEmpty(unevaluatedDefaultFlags)) {
    return Promise.resolve(featureFlagsFromRedux) as Promise<T>;
  }

  const queryOverrides = getQueryOverrides<T>(unevaluatedDefaultFlags, query);

  if (isBot && !options.localFetch) {
    const evaluations = {
      ...unevaluatedDefaultFlags,
      ...queryOverrides,
    };
    return Promise.resolve(evaluations);
  }

  const flagsToCheck =
    typeof unevaluatedDefaultFlags === "object" && unevaluatedDefaultFlags
      ? Object.keys(unevaluatedDefaultFlags).filter(flag => !(flag in queryOverrides))
      : [];
  const featureFlagRequest = {
    overrideAttribute: {
      ...overrideAttribute,
      domain: process.env.NEXT_PUBLIC_DOMAIN,
      isBot: JSON.stringify(isBot),
    },
    anonymous,
    featureFlagKeys: flagsToCheck,
  };

  const controller = new AbortController();

  const timeout = setTimeout(
    () => {
      controller.abort(new NonLoggableError("GET /hib/feature-flags timed out."));
    },
    dev ? 20000 : 3000,
  );

  const config = {
    method: "POST",
    body: JSON.stringify(featureFlagRequest),
    signal: controller.signal,
    headers: {
      ...api.getDefaultHeaders(req, res, true),
      "Content-Type": "application/json",
    },
  };

  return localFetch(FEATURE_FLAG_ENDPOINT, config)
    .then(catchNonLoggableErrors)
    .then(response => response.json() as Promise<EvaluationResponse<T>>)
    .then(getSuccessfulEvaluations)
    .then(evaluations => ({
      ...flagDefaultPairs,
      ...featureFlagsFromRedux,
      ...evaluations,
      ...queryOverrides,
    }))
    .then(tee(result => dev && console.log("Feature flag evaluations: ", result)))
    .catch(err => {
      if (!(err instanceof NonLoggableError)) {
        Sentry.captureException(err, scope => {
          scope.setContext("Request Body", featureFlagRequest);
          scope.setContext("Feature Flags", flagDefaultPairs as Record<string, unknown>);
          scope.setContext("Request Metadata", config);
          return scope;
        });
      }
      return flagDefaultPairs;
    })
    .finally(() => {
      clearTimeout(timeout);
    });
};

class NonLoggableError extends Error {}

const catchNonLoggableErrors = (response: Response) => {
  if ([401, 403, 500].includes(response.status)) {
    throw new NonLoggableError(response.statusText);
  }
  return response;
};
