import * as Sentry from "@sentry/nextjs";
import moment from "moment-timezone";
import QueryString from "qs";

import {isBrowser} from "./isBrowser";
import monolith from "./fetch/fetches";
import {parseUtmParams} from "./parseUtmParams";
import {NextRequest} from "next/server";
import {getVisitId, getVisitorId} from "./visitData";
import {AnyNextRequest, AnyNextResponse} from "../app/_pages-transition/server";
import {removeFalsyKeys} from "./removeFalsyKeys";

export type VisitData = {
  visitId: string;
  visitorId: string;
};

export const getVisitDataHeaders = (req?: AnyNextRequest, res?: AnyNextResponse) => ({
  CarbonVisitId: getVisitId(req, res),
  CarbonVisitorId: getVisitorId(req, res),
});

export const visitTracking = async ({req}: {req: NextRequest}): Promise<VisitData> => {
  if (isBrowser()) throw new Error("Visit tracking is not allowed on client.");
  const query = QueryString.parse(req.url);

  const utm = parseUtmParams(query);

  const headers = removeFalsyKeys({
    Referer: req.headers.get("referer"),
    "user-agent": req.headers.get("user-agent"),
  });

  try {
    const tracking = await monolith({allowedStatusCodes: [403]})("/hib/visit-tracking", {
      method: "POST",
      headers: {
        ...headers,
        ...getVisitDataHeaders(req),
      } as HeadersInit,
      body: JSON.stringify({utm, landingUrl: req.url, timezone: moment.tz.guess()}),
    });
    if (tracking.status == 403) {
      return {visitId: "", visitorId: ""};
    } else {
      const response = await tracking.json();
      return {visitId: response.visitId, visitorId: response.visitorId};
    }
  } catch (e) {
    const msg = `endpoint: /hib/visit-tracking . error: ${e}`;
    console.log(msg);
    Sentry.captureException(msg);
    return {visitId: "", visitorId: ""};
  }
};
